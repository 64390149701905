<template>
  <div class="job-detail">
    <div class="d-container" v-loading="loading">
      <ul class="d-header">
        <li class="u-item">
          <!-- 岗位名称 -->
          <h1>{{ jobData.job_name }}</h1>
        </li>
        <li class="u-item">
          <!--薪酬 -->
          <h2 v-if="jobData.min_salary_range ==0 || jobData.max_salary_range ==0">面议</h2>
          <h2 v-else>{{ jobData.min_salary_range }}-{{ jobData.max_salary_range}}k</h2>
        </li>
        <li class="u-item">
          <ul class="i-bottom">
            <!--工作地点 -->
            <li class="b-item">{{ jobData.place_of_work }}</li>
            <!--工作经验 -->
            <!-- <li class="b-item">{{ jobData.work_exp }}</li> -->
            <!--教育程度 -->
            <li class="b-item" v-if="jobData.education">{{ jobData.education }}</li>
            <!--招聘人数 -->
            <li class="b-item">招{{ jobData.job_num }}人</li>
          </ul>         
        </li>
        <li class="u-item">
          <el-button type="primary" @click="getPone(jobData.id)">拨打电话</el-button>
        </li>
        <span class="collection"  @click.stop="collection(jobData.id)">收藏</span>

      </ul>
      <div class="d-main">
        <ul class="m-detail">
          <li class="d-item">
            <div class="i-title">职位描述</div>
            <div class="i-text" style="white-space: pre-line; word-break: break-all">
              {{ jobData.job_description }}
            </div>
          </li>
          <li class="d-item">
            <div class="i-title">职位福利</div>
            <div class="i-text">
              <el-tag type="primary" v-for="(tag, index) in jobData.welfare" :key="index">{{ tag }}</el-tag>
            </div>
          </li>
          <li class="d-item">
            <div class="i-title">工作地点</div>
            <div class="i-text">{{ jobData.place_of_work }}</div>
          </li>
        </ul>
        <!-- <ul class="m-company">
          <li class="c-item">
            <el-image :src="companyInfo.image" fit="scale-down" v-if="companyInfo.image"></el-image>
            <el-image v-else>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </li>
          <li class="c-item">
            <p>{{ companyInfo.company_full }}</p>
          </li>
          <li class="c-item">
            <div>
              <i class="fa fa-th-large"></i>
              <span>
                {{ companyInfo.industry }}
              </span>
            </div>
            <div class="mt5">
              <i class="fa fa-user mr5"></i>
              <span>{{ companyInfo.num_scale }}人</span>
            </div>
            <div class="mt5">
              <i class="fa fa-phone-square mr5"></i>
              <span>{{ companyInfo.company_phone }}</span>
            </div>
          </li>
          <li class="c-item" style="white-space: pre-line; word-break: break-all">
            {{ companyInfo.company_profile }}
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getJobDetailListApi
  } from '@/api/home'
  import {getUserPhoneApi} from '@/api/user'
  import{collectionApi } from '@/api/occupation'

  export default {
    name: 'jobDetail',

    data() {
      return {
        id: '',
        j_id: '',
        jobData: {},
        // companyInfo: '',
        loading: false,
      }
    },
    
    created() {
      this.j_id = this.$route.query.id
      this.getJobDetailList()
    },
    methods: {
      async getJobDetailList() {
        this.loading = true
        const {
          data: res
        } = await getJobDetailListApi(this.j_id)
        if (res.status !== 10000) {
          return this.$message.error(res.message)
        }
        this.jobData = res.result
        // this.companyInfo = res.result.jobInfo
        this.loading = false
      },
      async collection(id){       
        this.token = localStorage.getItem('school_token')
        if (this.token) {
              const{data: res} = await collectionApi(id)
              return this.$message.success(res.message) 
        }else{
          this.$store.commit('saveloginStatus', 1)
          this.$store.commit('saveloginDialog', true)
        }
      },
      async getPone(id){ 
            this.token = localStorage.getItem('school_token')
            if (this.token) {
                  const {
                    data: res
                } = await getUserPhoneApi(id)
                if (res.status != 10000) {
                    return this.$message.error(res.message)
                }
                this.phone = res.result
                this.$MessageBox.alert('电话: '+this.phone.user_phone, '联系方式', {
                confirmButtonText: '确定',               
                }).then(() =>{}).catch(() =>{}); 
            }else{
              this.$store.commit('saveloginStatus', 1)
              this.$store.commit('saveloginDialog', true)
            }                                 
            }
    },
  }

</script>

<style lang="less" scoped>
  .job-detail {
    .d-container {
      width: 1200px;
      margin: 0 auto;
      padding-top: 20px;

      .d-header {
        position: relative;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background: #eee;
        padding-left: 20px;

        .collection{
          position: absolute;
          right: 30px;
          top: 27px;
          cursor: pointer;
          color: #d10a07;
        }

        .u-item h1{
          width: 1100px;
        }

        .u-item {
          h2 {
            color: #fd7240;
          }

          .i-bottom {
            display: flex;
            color: #8d92a1;
            font-size: 12px;

            .b-item {
              // border-left: 1px solid #8d92a1;
              padding: 0px 10px;
              margin: 10px 0px;
              line-height: 12px;
            }

            .b-item:first-child {
              padding-left: 0px;
              border-left: none;
            }

            .t-money {
              color: #fd7240;
            }
          }
        }
      }

      .d-main {
        display: flex;
        justify-content: space-between;
        padding: 20px 0px;

        .m-detail {
          width: 100%;
          margin-top: 20px;

          .d-item {
            margin-bottom: 10px;

            .i-title {
              color: #333;
              font-size: 16px;
              font-weight: 700;
              position: relative;
            }

            .i-title:after {
              background: #eee;
              content: "";
              display: inline-block;
              height: 1px;
              position: absolute;
              right: 15px;
              top: 12px;
              width: 89%;
            }

            .i-text {
              padding: 20px 0px;

              .el-tag {
                margin-right: 10px;
                background-color: #e5f4ff;
                color: #0092ff;
              }
            }
          }
        }

        .m-company {
          width: 300px;
          margin-left: 20px;
          padding: 0px 10px;

          .c-item {
            margin-bottom: 20px;
            font-size: 13px;

            .el-image {
              width: 64px;
              height: 64px;
            //   border-radius: 50%;
            //   overflow: hidden;
            }

            p {
              font-size: 16px;
            }

            span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

.el-button--primary{
  background-color: #409EFF;
  border-color: #409EFF;
  color: #FFF;
}
.el-button--primary:hover{
    background: #66b1ff;
    border-color: #66b1ff;
    color: #fff;
}

</style>
